import React from 'react'

// ** Next Import
import Image from 'next/image'

// Views
import CircleLoader from 'react-spinners/CircleLoader'

function LoadingSimpleView() {
  return (
    <div
      className='page-center-container'
      style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'column' }}
    >
      <Image src={'/images/logo.png'} alt={'logo'} width={80} height={80} />
      <CircleLoader color={'#002984'} loading={true} size={50} />
    </div>
  )
}

export default LoadingSimpleView
